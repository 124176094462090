<template src='./UploadAsset.html' />
<script lang='ts'>
import {
  getMediaId,
  ASSET_TYPE,
  AnalyticsAsset,
  Account,
} from '@/stores/model/domain'
import {analyticsState} from '@/stores/state/analytics/analytics'
import {auth} from '@/stores/authorization-state'
import {ErrorUploadAsset, analyticsUploadAssetRepository} from '@/stores/repository/analytics_upload_asset'
import {CSVReader, Row} from '@/csv-reader'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import Multiselect from '@vueform/multiselect'
import {defineComponent, ref, computed} from 'vue'

const UploadAssetComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    // input tag
    const inputFile = ref()
    // Upload File
    const isFileValid = ref<boolean>(false)
    const isFileError = ref<boolean>(false)
    const fileName = ref<string|null>(null)
    const fileUploadErrorMessage = ref<string|null>(null)
    const uploadCsvAssets = ref<AnalyticsAsset[]>([])
    // ダウンロードアセット
    const inputAnalyticsId = ref<string>('CampaignId')
    const inputCampaignAdgroupIds = ref<string>('')
    const downloadAssetsErrorMessage = ref<string|null>(null)

    // Client
    const selectedClient = computed(() => {
      return analyticsState.selectedClient
    })

    // Account
    const selectedAccount = computed(() => {
      return analyticsState.selectedAccount
    })
    const accounts = computed(() => {
      return analyticsState.accounts
    })

    const onSelectAccount = (account: Account) => {
      analyticsState.setSelectedAccount(account)
    }

    const resetFileValues = () => {
      // file
      isFileValid.value = false
      isFileError.value = false
      fileName.value = null
      uploadCsvAssets.value = []
    }

    const downloadFormat = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateUploadAssetFormat()
      downloader.download(content, 'UploadAsset_Format')
    }

    // File
    const clickDragdropArea = () => {
      // クリックしたとき、ファイル選択ダイアログが開くようにする
      inputFile.value.click()
    }

    const setFile = (event: any) => {
      resetFileValues()
      const files = event.target.files ? event.target.files : event.dataTransfer.files
      const file: File = files[0]

      csvToUploadAsset(file).then(removeEmptyUploadAsset).then((uploadAssets) => {
        if (uploadAssets.length <= 0) {
          return Promise.reject([{
            row_num: 0,
            error_message: 'マッチする行がありません',
          }])
        }
        analyticsState.setLoadingOn()
        analyticsUploadAssetRepository.checkUploadAssets({
          token: auth.token,
          upload_assets: uploadAssets,
        }).then(() => {
          uploadCsvAssets.value = uploadAssets
          fileName.value = file.name
          isFileValid.value = true
          analyticsState.setLoadingOff()
        }).catch((errors: ErrorUploadAsset[]) => {
          fileUploadErrorMessage.value = errors.map((errorAsset) => {
            return `${errorAsset.row_num}行目：${errorAsset.error_message}`
          }).join('<br/>')
          isFileError.value = true
          analyticsState.setLoadingOff()
        })
      })
    }

    // csvのAssetをまとめてアップロード＆結果をcsvダウンロード
    const uploadDownloadAssets = () => {
      analyticsState.setLoadingOn()
      analyticsUploadAssetRepository.postUploadDownloadAssets({
        token: auth.token,
        upload_assets: uploadCsvAssets.value,
        user: auth.employeeNumber,
      }).then((assetsWithHeaders) => {
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateUploadAssetContent(assetsWithHeaders)
        downloader.download(content, 'Assets')
        analyticsState.setLoadingOff()
      }).catch(() => {
        fileUploadErrorMessage.value = 'サーバエラー'
        isFileError.value = true
        analyticsState.setLoadingOff()
      })
    }

    const csvToUploadAsset = (file: File) => {
      if (file) {
        const reader = new CSVReader()
        return reader.read(file, true).then((rows: Row[]) => {
          return rows.map((row, index) => {
            // 空行の場合はnullを返す
            if (row.values.some((value) => value)) {
              const ssId = parseInt(row.values[0], 10)
              const mediaIdRaw = parseInt(row.values[1], 10)
              const assetNo: number = parseInt(row.values[5], 10)
              if (isNaN(ssId) || isNaN(mediaIdRaw)) { return null }
              const mediaId = getMediaId(mediaIdRaw)
              if (!mediaId) { return null }
              if (row.values[6] && row.values[6].length > 0) {
                return {
                  row_num: index + 1,
                  ss_id: ssId,
                  media_id: mediaId,
                  account_id: null,
                  campaign_id: row.values[2],
                  adgroup_id: row.values[3],
                  keyword_id: row.values[4],
                  word: row.values[6],
                  pw_asset_type: ASSET_TYPE.TITLE,
                  asset_no: assetNo ? assetNo : null,
                }
              } else if (row.values[7] && row.values[7].length > 0) {
                return {
                  row_num: index + 1,
                  ss_id: ssId,
                  media_id: mediaId,
                  account_id: null,
                  campaign_id: row.values[2],
                  adgroup_id: row.values[3],
                  keyword_id: row.values[4],
                  word: row.values[7],
                  pw_asset_type: ASSET_TYPE.DESCRIPTION,
                  asset_no: assetNo ? assetNo : null,
                }
              } else { return null }
            } else { return null }
          })
        })
      } else {
        return Promise.reject(['CSVファイルロードに失敗しました'])
      }
    }

    const removeEmptyUploadAsset = (uploadAssets: Array<AnalyticsAsset | null>) => {
      const filtered: AnalyticsAsset[] = uploadAssets.filter((asset) => asset) as AnalyticsAsset[]
      return filtered
    }

    // Download Assets
    const canDownloadAssets = computed(() => {
      const client = analyticsState.selectedClient
      const account = analyticsState.selectedAccount
      return !!client && !!account
    })

    const getIds: () => string[] = () => {
      const ids = inputCampaignAdgroupIds.value.split(/[,\n]/).flatMap((id) => {
        const trimed = id.trim()
        if (trimed.length <= 0) {
          return []
        }
        return trimed
      }) as string[]
      return ids
    }

    const downloadAssetsChampionKW = () => {
      downloadAssetsErrorMessage.value = null
      const account = analyticsState.selectedAccount
      if (!account) {
        return
      }
      const ids = getIds()

      if (ids.some((id) => isNaN(Number(id)) || id.length <= 0)) {
        downloadAssetsErrorMessage.value = 'idは半角数字で入力してください'
        return
      }
      let campaignIds: string[] = []
      let adgroupIds: string[] = []
      if (inputAnalyticsId.value === 'CampaignId') {
        campaignIds = ids
      } else if (inputAnalyticsId.value === 'AdGroupId') {
        adgroupIds = ids
      }
      analyticsState.setLoadingOn()
      analyticsUploadAssetRepository.fetchDownloadAssetsChampionKW({
        token: auth.token,
        account,
        campaignIds,
        adgroupIds,
      }).then((assetsWithHeaders) => {
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateDownloadAssetContent(assetsWithHeaders)
        downloader.download(content, 'AssetsChampionKW')
        analyticsState.setLoadingOff()
      }).catch(() => {
        downloadAssetsErrorMessage.value = 'サーバエラー'
        analyticsState.setLoadingOff()
      })
    }

    const downloadAssetsHigherKW = () => {
      downloadAssetsErrorMessage.value = null
      const account = analyticsState.selectedAccount
      if (!account) {
        return
      }
      const ids = getIds()

      if (ids.some((id) => isNaN(Number(id)) || id.length <= 0)) {
        downloadAssetsErrorMessage.value = 'idは半角数字で入力してください'
        return
      }
      let campaignIds: string[] = []
      let adgroupIds: string[] = []
      if (inputAnalyticsId.value === 'CampaignId') {
        campaignIds = ids
      } else if (inputAnalyticsId.value === 'AdGroupId') {
        adgroupIds = ids
      }
      analyticsState.setLoadingOn()
      analyticsUploadAssetRepository.fetchDownloadAssetsHigherKW({
        token: auth.token,
        account,
        campaignIds,
        adgroupIds,
      }).then((assetsWithHeaders) => {
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateDownloadAssetContent(assetsWithHeaders)
        downloader.download(content, 'AssetsHigherKW')
        analyticsState.setLoadingOff()
      }).catch(() => {
        downloadAssetsErrorMessage.value = 'サーバエラー'
        analyticsState.setLoadingOff()
      })
    }

    // Multiselect用
    const selectOptions = computed(() => {
      return analyticsState.accountOptions
    })
    // TODO:// value 変える
    const value = ref<Account | null>(null)

    return {
      inputFile,
      isFileValid,
      isFileError,
      fileName,
      fileUploadErrorMessage,
      inputAnalyticsId,
      inputCampaignAdgroupIds,
      downloadAssetsErrorMessage,
      uploadCsvAssets,
      selectedClient,
      selectedAccount,
      accounts,
      onSelectAccount,
      downloadFormat,
      clickDragdropArea,
      setFile,
      uploadDownloadAssets,
      canDownloadAssets,
      downloadAssetsChampionKW,
      downloadAssetsHigherKW,
      selectOptions,
      value,
    }
  },
})
export default UploadAssetComponent
</script>
