<div class="main_pannel">
    <HeaderComponent/>
    <article>
        <div class="analytics_main">
            <div class="analytics_tabs">
                <div class="btn go_creator" v-bind:class="{ current: pageType === 'UploadAsset' }" @click="changePageTypeUploadAsset">Upload Asset</div>
                <div class="btn go_creator_td" v-bind:class="{ current: pageType === 'UploadTd' }" @click="changePageTypeUploadTd">Upload TD</div>
            </div>

            <div style="margin:10px;padding:15px;font-size:20px;border-color:black;background-color:#cccccc;"><span style="color: red">⚠</span>️本機能は2024年9月30日<span style="color:red;">クローズ</span>予定です。何かあればAI事業本部の吉田浩一までご連絡ください。</div>

            <UploadAssetComponent v-show="pageType === 'UploadAsset'"/>
            <UploadTdComponent v-show="pageType === 'UploadTd'"/>
        </div>
    </article>
    <div class="loading_screen" v-if="isLoading">
        <div class="loading_image"></div>
    </div>
</div>
