<template src='./Header.html'></template>
<script lang='ts'>
import { auth } from '@/stores/authorization-state'
import {
  Client,
} from '@/stores/model/domain'
import {analyticsState} from '@/stores/state/analytics/analytics'
import {clientRepository} from '@/stores/repository/client'
import Multiselect from '@vueform/multiselect'
import {accountRepository} from '@/stores/repository/account'
import {computed, defineComponent, ref} from 'vue'

const HeaderComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    const fetchSetClients = () => {
      analyticsState.setIsLoadingClients(true)
      return clientRepository.fetchClients({ token: auth.token })
          .then((v: Client[]) => {
            analyticsState.setIsLoadingClients(false)
            analyticsState.setClients(v)
          })
    }
    const clients = computed(() => {
      return analyticsState.clients
    })
    const isLoadingClients = computed(() => {
      return analyticsState.loading.clients
    })
    const selectedClient = computed(() => {
      return analyticsState.selectedClient
    })

    const onSelectClient = (client: Client) => {
      analyticsState.setSelectedClient(client)
      analyticsState.setLoadingOn()
      // Account
      const ap = accountRepository.fetchAccounts({
        token: auth.token,
        clientId: client.client_id,
      }).then((as) => {
        analyticsState.setAccounts(as)
      })
      Promise.all([ap]).then(() => {
        analyticsState.setLoadingOff()
      })
    }

    // Multiselect用
    const selectOptions = computed(() => {
      return analyticsState.clientOptions
    })
    // TODO:// value 変える
    const value = ref<Client | null>(null)

    fetchSetClients()

    return {
      clients,
      isLoadingClients,
      selectedClient,
      onSelectClient,
      selectOptions,
      value
    }
  },
})
export default HeaderComponent
</script>


