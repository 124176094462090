import { reactive } from 'vue'
import {
  Account,
  AdGroup,
  Client,
  Keyword,
  PowerWordAsset,
  ASSET_TYPE,
  ClientOption,
  AccountOption,
  AdGroupOption,
} from '@/stores/model/domain'

interface PowerWordLoading {
  main: boolean,
  clients: boolean,
  adgroups: boolean,
  keywords: boolean,
}

interface PowerWordLoadingModal {
  deleteKeywordsModal: boolean,
  bulkRegisterKeywordsModal: boolean,
  registerKeywordsModal: boolean,
  bulkDeleteKeywordsModal: boolean,
  produceRSAHistoryModal: boolean,
  bannedAssetModal: boolean,
}

interface PowerWordState {
  loading: PowerWordLoading,
  // Modal切り替え
  modal: PowerWordLoadingModal,
  // Search
  searchPWAssetType: ASSET_TYPE,
  searchPWAssetWord: string | null,
  // Client
  clients: Client[],
  clientOptions: ClientOption[],
  selectedClient: Client | null,
  // Account
  accounts: Account[],
  accountOptions: AccountOption[],
  selectedAccount: Account | null,
  // AdGroup（RegisterKeywordModalで使用）
  adGroups: AdGroup[],
  adGroupOptions: AdGroupOption[],
  selectedAdGroup: AdGroup | null,
  // Keyword
  keywords: Keyword[],
  selectedKeyword: Keyword | null,
  candidateKeywords: Keyword[],
  candidateKeywordsForViews: Keyword[],
  // PowerWordAsset
  pwAssets: PowerWordAsset[],
  pwAssetsForView: PowerWordAsset[],
  // Method
  // Search PWAsset
  setSearchPWAssetType: (pwAssetType: ASSET_TYPE) => void,
  setSearchPWAssetWord: (pwAssetWord: string) => void,
  // Client
  setClients: (clients: Client[]) => void,
  setIsLoadingClients: (bool: boolean) => void,
  setSelectedClient: (client: Client | null) => void,
  // Account
  setAccounts: (accounts: Account[]) => void,
  setSelectedAccount: (account: Account | null) => void,
  // AdGroup
  setIsLoadingAdGroups: (bool: boolean) => void,
  setAdGroups: (adGroups: AdGroup[]) => void,
  setSelectedAdGroup: (adGroup: AdGroup | null) => void,
  // Keyword
  setKeywords: (keywords: Keyword[]) => void,
  setSelectedKeyword: (keyword: Keyword | null) => void,
  setCandidateKeywords: (candidateKeywords: Keyword[]) => void,
  reloadCandidateKeywordsForView: (keywordFilters: string[]) => void,
  setKeywordsLoading: (isLoading: boolean) => void,
  // PowerWord Asset
  setPowerWordAssets: (pwAssets: PowerWordAsset[]) => void,
  reloadPowerWordAssetsForView: () => void,
  // main loading
  setLoadingOn: () => void,
  setLoadingOff: () => void,
  // Modal切り替え
  setRegisterKeywordsModal: (bool: boolean) => void,
  setBulkRegisterKeywordsModal: (bool: boolean) => void,
  setDeleteKeywordsModal: (bool: boolean) => void,
  setBulkDeleteKeywordsModal: (bool: boolean) => void,
  setProduceRSAHistoryModal: (bool: boolean) => void,
  setBannedAssetModal: (bool: boolean) => void,
}

export const powerwordState = reactive<PowerWordState>({
  loading: {
    main: false,
    clients: false,
    adgroups: false,
    keywords: false,
  },
  // Modal切り替え
  modal: {
    deleteKeywordsModal: false,
    bulkRegisterKeywordsModal: false,
    registerKeywordsModal: false,
    bulkDeleteKeywordsModal: false,
    produceRSAHistoryModal: false,
    bannedAssetModal: false,
  },
  // Search
  searchPWAssetType: ASSET_TYPE.ALL,
  searchPWAssetWord: null,
  // Client
  clients: [],
  clientOptions: [],
  selectedClient: null,
  // Account
  accounts: [],
  accountOptions: [],
  selectedAccount: null,
  // AdGroup（RegisterKeywordModalで使用）
  adGroups: [],
  adGroupOptions: [],
  selectedAdGroup: null,
  // Keyword
  keywords: [],
  selectedKeyword: null,
  candidateKeywords: [],
  candidateKeywordsForViews: [],
  // PowerWordAsset
  pwAssets: [],
  pwAssetsForView: [],
  // Method
  // Search PWAsset
  setSearchPWAssetType: (pwAssetType: ASSET_TYPE) => {
    powerwordState.searchPWAssetType = pwAssetType
  },
  setSearchPWAssetWord: (pwAssetWord: string) => {
    powerwordState.searchPWAssetWord = pwAssetWord
  },
  // Client
  setClients: (clients: Client[]) => {
    powerwordState.clients = clients
    powerwordState.clientOptions = clients.map((c) => {
      return {
        value: c,
        label: c.client_name,
        track_by: c.client_id + ':' + c.client_name,
      }
    })
  },
  setIsLoadingClients: (bool: boolean) => {
    powerwordState.loading.clients = bool
  },
  setSelectedClient: (client: Client | null) => {
    powerwordState.selectedClient = client
  },
  // Account
  setAccounts: (accounts: Account[]) => {
    powerwordState.accounts = accounts
    powerwordState.accountOptions = accounts.map((a) => {
      return {
        value: a,
        label: `${a.account_id}(${a.ss_id}) - ${a.account_name}`,
        track_by: a.account_id + ':' + a.ss_id + ':' + a.account_name,
      }
    })
  },
  setSelectedAccount: (account: Account | null) => {
    powerwordState.selectedAccount = account
  },
  // AdGroup
  setIsLoadingAdGroups: (bool: boolean) => {
    powerwordState.loading.adgroups = bool
  },
  setAdGroups: (adGroups: AdGroup[]) => {
    powerwordState.adGroups = adGroups
    powerwordState.adGroupOptions = adGroups.map((adg) => {
      return {
        value: adg,
        label: `キャンペーン名（${adg.campaign_id}）：${adg.campaign_name}　　アドグループ名（${adg.adgroup_id}）：${adg.adgroup_name}`,
        track_by: `${adg.campaign_id}：${adg.campaign_name}：${adg.adgroup_id}：${adg.adgroup_name}`,
        disabled: !adg.campaign_status || !adg.adgroup_status,
      }
    })
  },
  setSelectedAdGroup: (adGroup: AdGroup | null) => {
    powerwordState.selectedAdGroup = adGroup
  },
  // Keyword
  setKeywords: (keywords: Keyword[]) => {
    powerwordState.keywords = keywords
  },
  setSelectedKeyword: (keyword: Keyword | null) => {
    powerwordState.selectedKeyword = keyword
  },
  setCandidateKeywords: (candidateKeywords: Keyword[]) => {
    powerwordState.candidateKeywords = candidateKeywords
  },
  reloadCandidateKeywordsForView: (keywordFilters: string[]) => {
    // フィルターにかけられたのキーワードのみ表示する
    if (keywordFilters.length <= 0) {
      powerwordState.candidateKeywordsForViews = powerwordState.candidateKeywords
      return
    }
    powerwordState.candidateKeywordsForViews = powerwordState.candidateKeywords.filter((candidateKeyword) =>
      // ORフィルタリング
      keywordFilters.some((keywordFilter) =>
        candidateKeyword.keyword.includes(keywordFilter) ||
        candidateKeyword.keyword_id === keywordFilter,
      ),
    )
  },
  setKeywordsLoading: (isLoading: boolean) => {
    powerwordState.loading.keywords = isLoading
  },
  // PowerWord Asset
  setPowerWordAssets: (pwAssets: PowerWordAsset[]) => {
    powerwordState.pwAssets = pwAssets
  },
  reloadPowerWordAssetsForView: () => {
    // PWアセットタイプや検索文字でfilterしたアセットのみ表示する
    const searchPWAssetType = powerwordState.searchPWAssetType
    const selectedKeyword = powerwordState.selectedKeyword
    if (!selectedKeyword) {
      return
    }
    const filteredPWAssets = powerwordState.pwAssets.filter((pa) => {
      if (!powerwordState.searchPWAssetWord) {
        return searchPWAssetType === ASSET_TYPE.ALL ||
          searchPWAssetType === pa.pw_asset_type
      }
      const searchPWAssetWords = powerwordState.searchPWAssetWord.split(/\s+/).filter((w) => w) as string[]
      const targetWord: string = pa.word +
        (pa.keyword ? pa.keyword : '')
      return searchPWAssetWords.every((searchWord) =>
        (String(pa.pw_asset_id) === searchWord || targetWord.includes(searchWord)) &&
        (
          searchPWAssetType === ASSET_TYPE.ALL ||
          searchPWAssetType === pa.pw_asset_type
        ),
      )
    })
    powerwordState.pwAssetsForView = filteredPWAssets
    return
  },
  // main loading
  setLoadingOn: () => {
    powerwordState.loading.main = true
  },
  setLoadingOff: () => {
    powerwordState.loading.main = false
  },
  // Modal切り替え
  setRegisterKeywordsModal: (bool: boolean) => {
    powerwordState.modal.registerKeywordsModal = bool
  },
  setBulkRegisterKeywordsModal: (bool: boolean) => {
    powerwordState.modal.bulkRegisterKeywordsModal = bool
  },
  setDeleteKeywordsModal: (bool: boolean) => {
    powerwordState.modal.deleteKeywordsModal = bool
  },
  setBulkDeleteKeywordsModal: (bool: boolean) => {
    powerwordState.modal.bulkDeleteKeywordsModal = bool
  },
  setProduceRSAHistoryModal: (bool: boolean) => {
    powerwordState.modal.produceRSAHistoryModal = bool
  },
  setBannedAssetModal: (bool: boolean) => {
    powerwordState.modal.bannedAssetModal = bool
  },
})
