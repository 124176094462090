import {
  AnalyticsUploadTd,
  AnalyticsDownloadTdsWithHeaders,
  MEDIA_ID,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface UploadTdRequest {
  upload_tds: AnalyticsUploadTd[]
  created_user: string
}

interface UploadDownloadResponses extends DataServiceResponse {
  headers: string[]
  results: UploadDownloadResponse[]
}

interface UploadDownloadResponse extends DataServiceResponse {
  media_id: MEDIA_ID
  account_id: string
  campaign_id: string
  adgroup_id: string
  keyword_id: string
  title_1: string | null
  title_2: string | null
  title_3: string | null
  description_1: string | null
  description_2: string | null
  score: number
}

class AnalyticsUploadTdRepository {
  public async postUploadDownloadTds(data: {
    token: string,
    upload_tds: AnalyticsUploadTd[],
    user: string,
  }): Promise<AnalyticsDownloadTdsWithHeaders> {
    const endpoint = `/analytics/upload_td/download`
    return DataService.post<UploadDownloadResponses>({
      path: endpoint,
      param: {
        upload_tds: data.upload_tds,
        created_user: data.user,
      },
      token: data.token,
    }).then((responses) => {
      return {
        headers: responses.headers,
        tds: responses.results.map((td) => {
          return {
            media_id: td.media_id,
            account_id: td.account_id,
            campaign_id: td.campaign_id,
            adgroup_id: td.adgroup_id,
            keyword_id: td.keyword_id,
            title_1: td.title_1,
            title_2: td.title_2,
            title_3: td.title_3,
            description_1: td.description_1,
            description_2: td.description_2,
            score: td.score,
          }
        }),
      }
    })
  }
}

export const analyticsUploadTdRepository = new AnalyticsUploadTdRepository()
