import {
  Client,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface ClientsResponse extends DataServiceResponse {
  clients: ClientResponse[]
}
interface ClientResponse extends DataServiceResponse {
  client_id: number
  client_name: string
}

class ClientRepository {
  private clientsEndpoint: string = '/clients'

  public async fetchClients(data: { token: string }): Promise<Client[]> {
    return DataService.getCache<ClientsResponse>({
      path: this.clientsEndpoint,
      param: { },
      token: data.token,
    }).then((cs) => {
      return cs.clients.map((c) => {
        return {
          client_id: c.client_id,
          client_name: c.client_name,
        }
      })
    })
  }
}

export const clientRepository = new ClientRepository()
