import { reactive } from 'vue'
import {
  Account,
  AccountOption,
  Client,
  ClientOption,
} from '@/stores/model/domain'

interface AnalyticsLoading {
  main: boolean,
  clients: boolean,
}

interface AnalyticsState {
  loading: AnalyticsLoading,
  // Client
  clients: Client[],
  clientOptions: ClientOption[],
  selectedClient: Client | null,
  // Account
  accounts: Account[],
  accountOptions: AccountOption[],
  selectedAccount: Account | null,
  // Method
  setLoadingOn: () => void,
  setLoadingOff: () => void,
  setClients: (clients: Client[]) => void,
  setIsLoadingClients: (bool: boolean) => void,
  setSelectedClient: (client: Client | null) => void,
  setAccounts: (accounts: Account[]) => void,
  setSelectedAccount: (account: Account | null) => void,
}

export const analyticsState = reactive<AnalyticsState>({
  loading: {
    main: false,
    clients: false,
  },
  clients: [],
  clientOptions: [],
  selectedClient: null,
  accounts: [],
  accountOptions: [],
  selectedAccount: null,
  // Method
  setLoadingOn: () => {
    analyticsState.loading.main = true
  },
  setLoadingOff: () => {
    analyticsState.loading.main = false
  },
  setClients: (clients: Client[]) => {
    analyticsState.clients = clients
    analyticsState.clientOptions = clients.map((c) => {
      return {
        value: c,
        label: c.client_name,
        track_by: c.client_id + ':' + c.client_name,
      }
    })
  },
  setIsLoadingClients: (bool: boolean) => {
    analyticsState.loading.clients = bool
  },
  setSelectedClient: (client: Client | null) => {
    analyticsState.selectedClient = client
  },
  setAccounts: (accounts: Account[]) => {
    analyticsState.accounts = accounts
    analyticsState.accountOptions = accounts.map((a) => {
      return {
        value: a,
        label: `${a.account_id}(${a.ss_id}) - ${a.account_name}`,
        track_by: a.account_id + ':' + a.ss_id + ':' + a.account_name,
      }
    })
  },
  setSelectedAccount: (account: Account | null) => {
    analyticsState.selectedAccount = account
  },
})
