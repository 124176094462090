<template src="./AssetRegistration.html"></template>
<script lang="ts">
import Multiselect from '@vueform/multiselect'
import {
  PowerWordAsset,
  KEYWORD_TYPE,
  ASSET_TYPE,
} from '@/stores/model/domain'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {auth} from '@/stores/authorization-state'
import {
  TITLE_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  MESSAGE_PLEASE_INPUT_ASSET,
  MESSAGE_TEXT_MAX_LENGTH,
  MESSAGE_FAILED_TO_DELETE_DATA,
} from '@/constants'
import {Format} from '@/utils'
import {textLength} from '@/utils/validator'
import {powerWordAssetRepository} from '@/stores/repository/powerword_asset'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import {defineComponent, ref, computed} from 'vue'
import useTdUtils from '@/utils/td-utils'

const AssetRegistrationComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    // Util
    const { scoreFormat } = useTdUtils()

    const inputPWAssetKeywordType = ref<string>('ByClient')
    const inputAssetType = ref<string>('Title')
    const inputTitle = ref<string | null>(null)
    const inputDescription = ref<string | null>(null)
    const estimateScore = ref<number | null>(null)

    const keywordTypeFormat = (powerwordKeywordType: KEYWORD_TYPE) => {
      return Format.keywordType(powerwordKeywordType)
    }
    const assetTypeFormat = (assetType: ASSET_TYPE) => {
      return Format.assetType(assetType)
    }

    // Keyword
    const selectedKeyword = computed(() => {
      return powerwordState.selectedKeyword
    })

    // PowerWordAsset
    const pwAssetsForView = computed(() => {
      return powerwordState.pwAssetsForView
    })

    // method
    const resetInputs = () => {
      estimateScore.value = null
      inputPWAssetKeywordType.value = 'ByClient'
      inputAssetType.value = 'Title'
      inputTitle.value = null
      inputDescription.value = null
    }

    const onFocus = (assetType: string) => {
      inputAssetType.value = assetType
    }

    const textFullLength = (str: string | null) => {
      return textLength(str)
    }

    // 保存
    const postAsset = () => {
      const client = powerwordState.selectedClient
      const keyword = powerwordState.selectedKeyword
      if (!client || !keyword) { return }
      const pwAssetKeywordType = inputPWAssetKeywordType.value === 'ByClient'
          ? KEYWORD_TYPE.BY_CLIENT : KEYWORD_TYPE.BY_KEYWORD
      const pwAssetType = inputAssetType.value === 'Title'
          ? ASSET_TYPE.TITLE : ASSET_TYPE.DESCRIPTION
      const word = pwAssetType === ASSET_TYPE.TITLE
          ? inputTitle.value : inputDescription.value
      if (!word) {
        alert(MESSAGE_PLEASE_INPUT_ASSET)
        return
      }
      // validation
      if (
          pwAssetType === ASSET_TYPE.TITLE && textLength(word) > TITLE_MAX_LENGTH ||
          pwAssetType === ASSET_TYPE.DESCRIPTION && textLength(word) > DESCRIPTION_MAX_LENGTH
      ) {
        alert(MESSAGE_TEXT_MAX_LENGTH)
        return
      }
      powerwordState.setLoadingOn()
      powerWordAssetRepository.postPowerWordAsset({
        token: auth.token,
        word,
        keyword,
        pwAssetKeywordType,
        pwAssetType,
        client,
        user: auth.employeeNumber,
      }).then((pas) => {
        resetInputs()
        powerwordState.setPowerWordAssets(pas)
        powerwordState.reloadPowerWordAssetsForView()
        powerwordState.setLoadingOff()
      })
    }

    // estimate（保存はしない）
    const estimateAsset = () => {
      const client = powerwordState.selectedClient
      const keyword = powerwordState.selectedKeyword
      const pwAssetKeywordType = inputPWAssetKeywordType.value === 'ByClient'
          ? KEYWORD_TYPE.BY_CLIENT : KEYWORD_TYPE.BY_KEYWORD
      if (!client || !keyword || pwAssetKeywordType !== KEYWORD_TYPE.BY_KEYWORD) {
        return
      }
      const pwAssetType = inputAssetType.value === 'Title'
          ? ASSET_TYPE.TITLE : ASSET_TYPE.DESCRIPTION
      const word = pwAssetType === ASSET_TYPE.TITLE
          ? inputTitle.value : inputDescription.value
      if (!word) {
        alert(MESSAGE_PLEASE_INPUT_ASSET)
        return
      }
      // validation
      if (
          pwAssetType === ASSET_TYPE.TITLE && textLength(word) > TITLE_MAX_LENGTH ||
          pwAssetType === ASSET_TYPE.DESCRIPTION && textLength(word) > DESCRIPTION_MAX_LENGTH
      ) {
        alert(MESSAGE_TEXT_MAX_LENGTH)
        return
      }
      powerwordState.setLoadingOn()
      powerWordAssetRepository.estimatePowerWordAsset({
        token: auth.token,
        word,
        keyword,
        pwAssetType,
        client,
        user: auth.employeeNumber,
      }).then((assetScore) => {
        estimateScore.value = assetScore
        powerwordState.setLoadingOff()
      })
    }

    const deleteAsset = (pwAsset: PowerWordAsset) => {
      if (confirm('削除しますか？')) {
        powerwordState.setLoadingOn()
        powerWordAssetRepository.deletePowerWordAsset({
          token: auth.token,
          pwAsset,
          user: auth.employeeNumber,
        }).then((pas) => {
          powerwordState.setPowerWordAssets(pas)
          powerwordState.reloadPowerWordAssetsForView()
          powerwordState.setLoadingOff()
        }).catch((e) => {
          // エラー表示
          alert(MESSAGE_FAILED_TO_DELETE_DATA)
          powerwordState.setLoadingOff()
        })
      }
    }

    // 絞り込み前の全PWAssetをcsv出力する
    const downloadPowerWordAssets = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const pwAssets = powerwordState.pwAssets
      powerwordState.setLoadingOn()
      const content = downloadContents.generatePowerWordAssetContent(pwAssets)
      downloader.download(content, 'PowerWordAssets')
      powerwordState.setLoadingOff()
    }

    // Search PWAsset（検索用：stateを介してTD Createと連動する）
    const searchPWAssetWord = computed(() => {
      return powerwordState.searchPWAssetWord
    })

    const isSearchPWAssetTypeAll = computed(() => {
      return powerwordState.searchPWAssetType === ASSET_TYPE.ALL
    })

    const isSearchPWAssetTypeTitle = computed(() => {
      return powerwordState.searchPWAssetType === ASSET_TYPE.TITLE
    })

    const isSearchPWAssetTypeDescription = computed(() => {
      return powerwordState.searchPWAssetType === ASSET_TYPE.DESCRIPTION
    })

    const changeSearchPWAssetType = (pwAssetType: string) => {
      switch (pwAssetType) {
        case 'All':
          powerwordState.setSearchPWAssetType(ASSET_TYPE.ALL)
          break
        case 'Title':
          powerwordState.setSearchPWAssetType(ASSET_TYPE.TITLE)
          break
        case 'Description':
          powerwordState.setSearchPWAssetType(ASSET_TYPE.DESCRIPTION)
          break
      }
      powerwordState.reloadPowerWordAssetsForView()
    }

    const changeSearchPWAssetWord = (event: any) => {
      if (!event) {
        return
      }
      powerwordState.setSearchPWAssetWord(event.target.value)
      powerwordState.reloadPowerWordAssetsForView()
    }

    return {
      scoreFormat,
      inputPWAssetKeywordType,
      inputAssetType,
      inputTitle,
      inputDescription,
      estimateScore,
      keywordTypeFormat,
      assetTypeFormat,
      selectedKeyword,
      pwAssetsForView,
      resetInputs,
      onFocus,
      textFullLength,
      postAsset,
      estimateAsset,
      deleteAsset,
      downloadPowerWordAssets,
      searchPWAssetWord,
      isSearchPWAssetTypeAll,
      isSearchPWAssetTypeTitle,
      isSearchPWAssetTypeDescription,
      changeSearchPWAssetType,
      changeSearchPWAssetWord,
    }
  },
})
export default AssetRegistrationComponent
</script>
