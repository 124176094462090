import {
  Money,
  Account,
  Keyword,
  AdGroup,
  getMatchType,
  MEDIA_ID, EXPECTED_CTR, AD_RELEVANCE,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface KeywordsResponse extends DataServiceResponse {
  keywords: KeywordResponse[]
}

export interface KeywordsWithImpConvResponse extends DataServiceResponse {
  total_imp: number
  results: KeywordWithImpConvResponse[]
}

export interface KeywordResponse extends DataServiceResponse {
  ss_id: number
  media_id: number
  account_id: string
  campaign_id: string
  campaign_name: string | null
  adgroup_id: string
  adgroup_name: string | null
  keyword_id: string
  keyword: string
  match_type: string
  daily_budget: Money | null
  final_url: string | null
  status: string | null
}

interface KeywordWithImpConvResponse extends KeywordResponse {
  imp: number
  conv: number
  expected_ctr: string
  ad_relevance: string
}

export function convertResponseToModel(k: KeywordResponse): Keyword {
  return {
    ss_id: k.ss_id,
    media_id: k.media_id,
    account_id: k.account_id,
    campaign_id: k.campaign_id,
    campaign_name: k.campaign_name,
    adgroup_id: k.adgroup_id,
    adgroup_name: k.adgroup_name,
    keyword_id: k.keyword_id,
    keyword: k.keyword,
    key: `${k.ss_id}:${k.campaign_id}:${k.adgroup_id}:${k.keyword_id}`, // TODO:// 旧勝ちアドのキーワード登録にしか使ってない。消したい
    match_type: getMatchType(k.match_type),
    daily_budget: k.daily_budget,
    final_url: k.final_url,
    status: k.status,
    track_id: k.keyword +
      ' [CPN]' + (k.campaign_name ? k.campaign_name : '') +
      ' [ADG]' + (k.adgroup_name ? k.adgroup_name : '') +
      ' [TYPE]' + getMatchType(k.match_type),
  }
}

class KeywordRepository {
  public async fetchKeywords(data: { token: string, ssId: number }): Promise<Keyword[]> {
    const endpoint = `/keywords/${data.ssId}`
    return DataService.get<KeywordsResponse>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k)
      })
    })
  }

  public async fetchCandidateKeywords(data: {
      token: string,
      adGroup: AdGroup,
    }): Promise<Keyword[]> {
    const endpoint = `/keywords/${data.adGroup.ss_id}/${data.adGroup.media_id}/${data.adGroup.campaign_id}/${data.adGroup.adgroup_id}`
    return DataService.getCache<KeywordsResponse>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k)
      })
    })
  }

  public async postKeyword(data: {
      token: string,
      user: string,
      candidateKeyword: Keyword,
      account: Account,
    }): Promise<Keyword[]> {
    const endpoint = `/keywords`
    return DataService.post<KeywordsResponse>({
      path: endpoint,
      param: {
        ss_id: data.candidateKeyword.ss_id,
        media_id: data.candidateKeyword.media_id,
        account_id: data.account.account_id,
        campaign_id: data.candidateKeyword.campaign_id,
        adgroup_id: data.candidateKeyword.adgroup_id,
        keyword_id: data.candidateKeyword.keyword_id,
        keyword: data.candidateKeyword.keyword,
        match_type: data.candidateKeyword.match_type,
        user: data.user,
      },
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k)
      })
    })
  }

  public async deleteKeyword(data: { token: string, user: string, keyword: Keyword }): Promise<Keyword[]> {
    const endpoint = `/keywords`
    return DataService.delete<KeywordsResponse>({
      path: endpoint,
      param: {
        ss_id: data.keyword.ss_id,
        campaign_id: data.keyword.campaign_id,
        adgroup_id: data.keyword.adgroup_id,
        keyword_id: data.keyword.keyword_id,
        user: data.user,
      },
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k)
      })
    })
  }

  public async fetchKeywordsWithImpConv(data: {
    token: string,
    ssId: number,
    mediaId: MEDIA_ID,
    campaignId: string,
    adgroupId: string,
  }): Promise<KeywordsWithImpConvResponse> {
    const endpoint = `/keywords/imp/${data.ssId}/${data.mediaId}/${data.campaignId}/${data.adgroupId}`
    return DataService.get<KeywordsWithImpConvResponse>({
      path: endpoint,
      param: {},
      token: data.token,
    })
  }
}

export const keywordRepository = new KeywordRepository()
