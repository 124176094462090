<template src="./PWAssetCreate.html"></template>
<style lang="scss" src="@/components/common/asset/sass/import.scss"></style>
<script lang="ts">
import {powerwordState} from '@/stores/state/powerword/powerword'
import HeaderComponent from '@/components/pages/PWAssetCreate/HeaderComponent/Header.vue'
import ProduceRSAComponent from '@/components/pages/PWAssetCreate/ProduceRSAComponent/ProduceRSA.vue'
import BulkEstimateComponent from '@/components/pages/PWAssetCreate/BulkEstimateComponent/BulkEstimate.vue'
import BulkRSAAdComponent from '@/components/pages/PWAssetCreate/BulkRSAAdComponent/BulkRSAAd.vue'
import BulkPreCombinedAssetComponent from '@/components/pages/PWAssetCreate/BulkPreCombinedAssetComponent/BulkPreCombinedAsset.vue'
import AccountKeywordComponent from '@/components/pages/PWAssetCreate/AccountKeywordComponent/AccountKeyword.vue'
import AssetRegistrationComponent from '@/components/pages/PWAssetCreate/AssetRegistrationComponent/AssetRegistration.vue'
import DeleteKeywordsModal from '@/components/pages/PWAssetCreate/Modals/DeleteKeywordsModal/DeleteKeywordsModal.vue'
import RegisterKeywordsModal from '@/components/pages/PWAssetCreate/Modals/RegisterKeywordsModal/RegisterKeywordsModal.vue'
import BulkRegisterKeywordsModal from '@/components/pages/PWAssetCreate/Modals/BulkRegisterKeywordsModal/BulkRegisterKeywordsModal.vue'
import BulkDeleteKeywordsModal from '@/components/pages/PWAssetCreate/Modals/BulkDeleteKeywordsModal/BulkDeleteKeywordsModal.vue'
import {defineComponent, ref, computed} from 'vue'

const enum POWER_WORD_PAGE_TYPE {
  PRODUCE_RSA = 'ProduceRSA',
  BULK_ESTIMATE = 'BulkEstimate',
  BULK_RSA_AD = 'BulkRSAAd',
  BULK_PRE_COMBINED_ASSET = 'BulkPreCombinedAsset',
  ASSET_REGISTRATION = 'AssetRegistration',
}

const PWAssetCreateComponent = defineComponent({
  components: {
    HeaderComponent,
    AccountKeywordComponent,
    ProduceRSAComponent,
    BulkEstimateComponent,
    BulkRSAAdComponent,
    BulkPreCombinedAssetComponent,
    AssetRegistrationComponent,
    DeleteKeywordsModal,
    RegisterKeywordsModal,
    BulkRegisterKeywordsModal,
    BulkDeleteKeywordsModal,
  },
  setup() {
    // Component Refs
    const assetRegistration = ref()

    // ref parameters
    const pageType = ref<POWER_WORD_PAGE_TYPE>(POWER_WORD_PAGE_TYPE.PRODUCE_RSA)
    const isLoading = computed(() => {
      return powerwordState.loading.main
    })
    const selectedClient = computed(() => {
      return powerwordState.selectedClient
    })
    const canShowKeywordInfo = computed(() => {
      return !!powerwordState.selectedKeyword &&
          pageType.value === POWER_WORD_PAGE_TYPE.ASSET_REGISTRATION
    })
    const selectedKeyword = computed(() => {
      return powerwordState.selectedKeyword
    })

    const resetInputs = () => {
      assetRegistration.value.resetInputs()
    }

    const changePageTypeProduceRSA = () => {
      pageType.value = POWER_WORD_PAGE_TYPE.PRODUCE_RSA
    }

    const changePageTypeBulkEstimate = () => {
      pageType.value = POWER_WORD_PAGE_TYPE.BULK_ESTIMATE
    }

    const changePageTypeBulkRSAAd = () => {
      pageType.value = POWER_WORD_PAGE_TYPE.BULK_RSA_AD
    }

    const changePageTypeBulkPreCombinedAsset = () => {
      pageType.value = POWER_WORD_PAGE_TYPE.BULK_PRE_COMBINED_ASSET
    }

    const changePageTypeAssetRegistration = () => {
      pageType.value = POWER_WORD_PAGE_TYPE.ASSET_REGISTRATION
    }

    return {
      assetRegistration,
      pageType,
      isLoading,
      selectedClient,
      canShowKeywordInfo,
      selectedKeyword,
      resetInputs,
      changePageTypeAssetRegistration,
      changePageTypeProduceRSA,
      changePageTypeBulkPreCombinedAsset,
      changePageTypeBulkEstimate,
      changePageTypeBulkRSAAd,
    }
  },
})
export default PWAssetCreateComponent
</script>
