<template src='./UploadTd.html' />
<script lang='ts'>
import {
  MEDIA_ID,
  AnalyticsUploadTd,
  AnalyticsDownloadTdsWithHeaders,
} from '@/stores/model/domain'
import {analyticsState} from '@/stores/state/analytics/analytics'
import {auth} from '@/stores/authorization-state'
import {analyticsUploadTdRepository} from '@/stores/repository/analytics_upload_td'
import {CSVReader, Row} from '@/csv-reader'
import {CSVDownloader} from '@/csv-downloader'
import {CSVDownloadContents} from '@/csv-download-contents'
import {defineComponent, ref} from 'vue'

const UploadTdComponent = defineComponent({
  components: {},
  setup() {
    // input file
    const inputFile = ref()
    // Upload File
    const isFileValid = ref<boolean>(false)
    const isFileError = ref<boolean>(false)
    const fileName = ref<string|null>(null)
    const errorMessage = ref<string|null>(null)
    const uploadCsvTds = ref<AnalyticsUploadTd[]>([])

    const resetValues = () => {
      // file
      isFileValid.value = false
      isFileError.value = false
      fileName.value = null
      uploadCsvTds.value = []
    }

    const downloadFormat = () => {
      const downloader = new CSVDownloader()
      const downloadContents = new CSVDownloadContents()
      const content = downloadContents.generateUploadTdFormat()
      downloader.download(content, 'UploadTd_Format')
    }

    // File
    const clickDragdropArea = () => {
      // クリックしたとき、ファイル選択ダイアログが開くようにする
      inputFile.value.click()
    }

    const setFile = (event: any) => {
      resetValues()
      const files = event.target.files ? event.target.files : event.dataTransfer.files
      const file: File = files[0]

      csvToUploadTd(file).then(removeEmptyUploadTd).then((uploadTds) => {
        if (uploadTds.length <= 0) {
          return Promise.reject(['マッチする行がありません。'])
        }
        uploadCsvTds.value = uploadTds
        fileName.value = file.name
        isFileValid.value = true
      }).catch((errors: string[]) => {
        errorMessage.value = errors.toString()
        isFileError.value = true
      })
    }

    // csvのTdをまとめてアップロード＆結果をcsvダウンロード
    const uploadDownloadTds = () => {
      analyticsState.setLoadingOn()
      analyticsUploadTdRepository.postUploadDownloadTds({
        token: auth.token,
        upload_tds: uploadCsvTds.value,
        user: auth.employeeNumber,
      }).then((tdsWithHeaders: AnalyticsDownloadTdsWithHeaders) => {
        const downloader = new CSVDownloader()
        const downloadContents = new CSVDownloadContents()
        const content = downloadContents.generateUploadTdContent(tdsWithHeaders)
        downloader.download(content, 'Tds')
        analyticsState.setLoadingOff()
      }).catch(() => {
        isFileError.value = true
        errorMessage.value = 'サーバエラー'
        analyticsState.setLoadingOff()
        return
      })
    }

    const csvToUploadTd = (file: File) => {
      if (file) {
        const reader = new CSVReader()
        return reader.read(file, true).then((rows: Row[]) => {
          return rows.map((row) => {
            // 空行の場合はnullを返す
            if (row.values.some((value) => value)) {
              let mediaId: MEDIA_ID = MEDIA_ID.GOOGLE
              if (row.values[0] === '1') {
                mediaId = MEDIA_ID.YAHOO
              } else if (row.values[0] === '2') {
                mediaId = MEDIA_ID.GOOGLE
              } else { return null }
              return {
                media_id: mediaId,
                account_id: row.values[1],
                campaign_id: row.values[2],
                adgroup_id: row.values[3],
                keyword_id: row.values[4],
                // keyword: row.values[5],
                // match_type: row.values[6],
                title_1: row.values[7],
                title_2: row.values[8],
                title_3: row.values[9],
                description_1: row.values[10],
                description_2: row.values[11],
              }
            } else { return null }
          })
        })
      } else {
        return Promise.reject(['CSVファイルロードに失敗しました'])
      }
    }

    const removeEmptyUploadTd = (uploadTds: Array<AnalyticsUploadTd | null>) => {
      const filtered: AnalyticsUploadTd[] = uploadTds.filter((asset) => asset) as AnalyticsUploadTd[]
      return filtered
    }

    return {
      inputFile,
      isFileValid,
      isFileError,
      fileName,
      errorMessage,
      uploadCsvTds,
      downloadFormat,
      clickDragdropArea,
      setFile,
      uploadDownloadTds,
    }
  },
})
export default UploadTdComponent
</script>
