import { reactive } from 'vue'
import {
  Client,
  NGWord,
  Account,
  AccountOption,
  ClientOption,
} from '@/stores/model/domain'

interface CheckLoading {
  main: boolean,
  clients: boolean,
}

interface CheckState {
  loading: CheckLoading,
  // Client
  clients: Client[],
  clientOptions: ClientOption[],
  selectedClient: Client | null,
  // NGWord
  ngWords: NGWord[],
  // Method
  // main loading
  setLoadingOn: () => void,
  setLoadingOff: () => void,
  // Client
  setClients: (clients: Client[]) => void,
  setIsLoadingClients: (bool: boolean) => void,
  setSelectedClient: (client: Client | null) => void,
  // NGWord
  setNGWords: (ngws: NGWord[]) => void,
}

export const checkState = reactive<CheckState>({
  loading: {
    main: false,
    clients: false,
  },
  // Client
  clients: [],
  clientOptions: [],
  selectedClient: null,
  // NGWord
  ngWords: [],
  // Method
  // main loading
  setLoadingOn: () => {
    checkState.loading.main = true
  },
  setLoadingOff: () => {
    checkState.loading.main = false
  },
  // Client
  setClients: (clients: Client[]) => {
    checkState.clients = clients
    checkState.clientOptions = clients.map((c) => {
      return {
        value: c,
        label: c.client_name,
        track_by: c.client_id + ':' + c.client_name,
      }
    })
  },
  setIsLoadingClients: (bool: boolean) => {
    checkState.loading.clients = bool
  },
  setSelectedClient: (client: Client | null) => {
    checkState.selectedClient = client
  },
  // NGWord
  setNGWords: (ngws: NGWord[]) => {
    checkState.ngWords = ngws
  },
})
