<div>
    <header>
        <div class="logo"></div>
        <div class="client_select">
            <div v-show="isLoadingClients" class="select_mask">
                <div class="select_loading"></div>
            </div>
            <Multiselect
                    class=""
                    placeholder="クライアント名で検索"
                    v-model="value"
                    value-prop="value"
                    :options="selectOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectClient">
            </Multiselect>
        </div>

        <div class="header_btn">
            <a class="btn" href="/pw_asset_create">Asset Create</a>
            <a class="btn" href="/check">Check</a>
            <div class="btn current">Analytics</div>
        </div>
    </header>
</div>
