import {
  AD_STRENGTH,
  MEDIA_ID,
  RSAProduct,
  RSAProductAsset,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface RSAProductResponse extends DataServiceResponse {
  rsa_product_id: number
  client_id: number
  media_id: MEDIA_ID
  ss_id: number
  account_id: string
  account_name: string
  campaign_id: string
  campaign_name: string
  adgroup_id: string
  adgroup_name: string
  rsa_product_score: number
  ad_strength: AD_STRENGTH
  pre_combined_rsa_id: string | null
  created_user: string
  created_at: string
  updated_user: string
  updated_at: string
  assets: RSAProductAsset[]
}

interface RSAProductResponses extends DataServiceResponse {
  results: RSAProductResponse[]
}

function convertResponseToModel(rpr: RSAProductResponse): RSAProduct {
  return {
    rsa_product_id: rpr.rsa_product_id,
    client_id: rpr.client_id,
    media_id: rpr.media_id,
    ss_id: rpr.ss_id,
    account_id: rpr.account_id,
    account_name: rpr.account_name,
    campaign_id: rpr.campaign_id,
    campaign_name: rpr.campaign_name,
    adgroup_id: rpr.adgroup_id,
    adgroup_name: rpr.adgroup_name,
    rsa_product_score: rpr.rsa_product_score,
    ad_strength: rpr.ad_strength,
    pre_combined_rsa_id: rpr.pre_combined_rsa_id,
    created_user: rpr.created_user,
    created_at: rpr.created_at,
    updated_user: rpr.updated_user,
    updated_at: rpr.updated_at,
    assets: rpr.assets,
  }
}

class RSAProductRepository {
  public async fetchRSAProductsBySSId(data: {
        token: string,
        ss_id: number,
        campaign_id: string,
        adgroup_id: string,
      }): Promise<RSAProduct[]> {
    const endpoint = `/rsa_products`
    return DataService.get<RSAProductResponses>({
      path: endpoint,
      param: {
        ss_id: data.ss_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
      },
      token: data.token,
    }).then((rprs) => {
      return rprs.results.map((rpr) => {
        return convertResponseToModel(rpr)
      })
    })
  }

  public async fetchRSAProductByPK(data: {
    token: string,
    rsa_product_id: number,
  }): Promise<RSAProduct> {
    const endpoint = `/rsa_products/assets`
    return DataService.get<RSAProductResponse>({
      path: endpoint,
      param: {
        rsa_product_id: data.rsa_product_id,
      },
      token: data.token,
    }).then((rpr) => {
      return convertResponseToModel(rpr)
    })
  }
}

export const rsaProductRepository = new RSAProductRepository()
