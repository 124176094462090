<div class="upload_td">
    <h2>TD Upload</h2>
    <div class="file_upload">
        <input class="upload_file" ref="inputFile" type="file" @change="setFile($event)">
        <div class="upload_area"
             @click="clickDragdropArea"
             v-bind:class="{'drag_over': isFileValid, 'drag_error': isFileError}"
             @dragover.prevent
             @dragleave.prevent
             @drop.prevent="setFile($event)">ドラック＆ドロップまたはクリック</div>
        <div v-show="isFileValid">
            <p>{{ fileName }}</p>
            <p>マッチする行数は{{ uploadCsvTds.length }}件です</p>
        </div>
        <p class="error" v-show="isFileError">{{ errorMessage }}</p>
        <div class="btn upload" @click="downloadFormat">Format download</div>
    </div>

    <div class="modal_action">
        <button class="btn run file_upload" v-bind:disabled="!isFileValid" @click="uploadDownloadTds">Upload & Download</button>
    </div>
    <p>FormatDownloadから、utf-16/タブ区切りのファイルをご利用ください</p>
</div>
