<style lang="scss" src="@/components/pages/PWAssetCreate/Modals/BannedAssetModal/BannedAssetModal.scss"></style>
<script lang='ts' setup>
import {
  CandidateAsset,
} from '@/stores/model/domain'
import {computed, ref} from 'vue'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {bannedAssetRepository} from '@/stores/repository/banned_asset';
import {auth} from '@/stores/authorization-state';

const candidateAsset = ref<CandidateAsset | null>(null)
const isActiveModal = computed(() => {
  return powerwordState.modal.bannedAssetModal
})
const reason = ref<string | null>(null)

// Client
const selectedClient = computed(() => {
  return powerwordState.selectedClient
})

const reload = (ca: CandidateAsset) => {
  powerwordState.setLoadingOn()
  reason.value = ''
  candidateAsset.value = ca
  powerwordState.setLoadingOff()
}

const closeBannedAssetModal = () => {
  powerwordState.setBannedAssetModal(false)
}

const emit = defineEmits(['afterBanAsset'])

const isDisablePost = computed(() => {
  return !selectedClient.value ||
      !candidateAsset.value ||
      candidateAsset.value.asset_text_default.length <= 0 ||
      !reason.value ||
      reason.value.length <= 0
})

const onClickPost = async () => {
  if (isDisablePost.value) { return }
  powerwordState.setLoadingOn()

  await bannedAssetRepository.post({
    token: auth.token,
    clientId: selectedClient.value!.client_id,
    asset_text: candidateAsset.value!.asset_text_default, // NGワードreplace実行後、手動調整前のasset_text
    reason: reason.value!,
    generator_asset_id: candidateAsset.value!.generator_asset_id,
    created_user: auth.employeeNumber,
  })

  emit('afterBanAsset', candidateAsset.value)
  powerwordState.setLoadingOff()
  closeBannedAssetModal()
}

defineExpose({
  reload,
});
</script>

<template>
  <div v-if="isActiveModal" class="banned_asset">
    <div class="overlay"></div>
    <div class="modal">
      <h2>アセット削除</h2>

        <p>アセット</p>
        <div class="delete_asset">{{ candidateAsset?.asset_text_default }}</div>
        <div class="delete_reason">
          <textarea v-model="reason" maxlength="200" placeholder="理由"></textarea>
        </div>
      <p>NGワードを活用できないか十分に検討した上で、できない理由を記載してください</p>

      <div class="modal_action">
        <button class="btn" @click="onClickPost" :disabled="isDisablePost">このアセットは二度と表示しない</button>
        <button class="btn cancel" @click="closeBannedAssetModal">Cancel</button>
      </div>
    </div>
  </div>
</template>
