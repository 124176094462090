<template src='./Header.html'></template>

<script lang='ts'>
import { checkState } from '@/stores/state/check/check'
import { auth } from '@/stores/authorization-state'
import {
  Client,
  NGWord,
} from '@/stores/model/domain'
import Multiselect from '@vueform/multiselect'
import {clientRepository} from '@/stores/repository/client'
import {ngWordRepository} from '@/stores/repository/ng_word'
import {computed, defineComponent, ref} from 'vue'

const CheckHeaderComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup(_, context) {
    const fetchSetClients = (query: string) => {
      checkState.setIsLoadingClients(true)
      return clientRepository.fetchClients({ token: auth.token })
        .then((v: Client[]) => {
          checkState.setIsLoadingClients(false)
          checkState.setClients(v)
      })
    }
    fetchSetClients('')

    const clients = computed(() => {
      return checkState.clients
    })

    const isLoadingClients = computed(() => {
      return checkState.loading.clients
    })

    const selectedClient = computed(() => {
      return checkState.selectedClient
    })

    // Multiselect用
    const selectOptions = computed(() => {
      return checkState.clientOptions
    })
    const selectedClientValue = ref<Client | null>(null)

    const onSelectClient = (client: Client) => {
      checkState.setSelectedClient(client)
      checkState.setLoadingOn()
      ngWordRepository.fetchNGWords({
        token: auth.token,
        clientId: client.client_id,
      }).then((ngws: NGWord[]) => {
        checkState.setNGWords(ngws)
      }).then(() => {
        context.emit('resetInputs')
        checkState.setLoadingOff()
      })
    }

    return {
      clients,
      isLoadingClients,
      selectedClient,
      selectOptions,
      selectedClientValue,
      onSelectClient,
    }
  },
})

export default CheckHeaderComponent
</script>


