import {
  AnalyticsDownloadAssetsWithHeaders,
  MEDIA_ID,
  ASSET_TYPE,
  AnalyticsAsset,
  Account,
  DownloadAssetsWithHeaders,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

// UploadFile
interface UploadAssetRequest {
  upload_assets: AnalyticsAsset[]
  created_user: string
}

export interface ErrorUploadAsset {
  row_num: number
  error_message: string
}

interface CheckUploadResponses extends DataServiceResponse {
  errors: ErrorUploadAsset[]
}


interface UploadDownloadResponses extends DataServiceResponse {
  headers: string[]
  results: UploadDownloadResponse[]
}

interface UploadDownloadResponse extends DataServiceResponse {
  row_num: number
  ss_id: number
  media_id: MEDIA_ID
  account_id: string
  campaign_id: string
  adgroup_id: string
  keyword_id: string
  word: string
  pw_asset_type: ASSET_TYPE
  score: number
  imp: number
  asset_no: number
}

// Download Assets
interface DownloadAssetResponses extends DataServiceResponse {
  headers: string[]
  results: DownloadAssetResponse[]
}

interface DownloadAssetResponse extends DataServiceResponse {
  ss_id: number
  media_id: MEDIA_ID
  account_id: string
  campaign_id: string
  adgroup_id: string
  keyword_id: string
  word: string
  asset_type: ASSET_TYPE
  score: number
  imp: number
  asset_no: number
}


class AnalyticsUploadAssetRepository {
  public async checkUploadAssets(data: {
    token: string,
    upload_assets: AnalyticsAsset[],
  }): Promise<void> {
    const endpoint = `/analytics/upload_asset/check`
    return DataService.post<CheckUploadResponses>({
      path: endpoint,
      param: {
        upload_assets: data.upload_assets,
      },
      token: data.token,
    }).then((responses) => {
      if (responses.errors.length > 0) {
        return Promise.reject(responses.errors)
      }
    })
  }

  public async postUploadDownloadAssets(data: {
    token: string,
    upload_assets: AnalyticsAsset[],
    user: string,
  }): Promise<AnalyticsDownloadAssetsWithHeaders> {
    const endpoint = `/analytics/upload_asset/download`
    return DataService.post<UploadDownloadResponses>({
      path: endpoint,
      param: {
        upload_assets: data.upload_assets,
        created_user: data.user,
      },
      token: data.token,
    }).then((responses) => {
      return {
        headers: responses.headers,
        assets: responses.results.map((asset) => {
          return {
            row_num: asset.row_num,
            ss_id: asset.ss_id,
            media_id: asset.media_id,
            account_id: asset.account_id,
            campaign_id: asset.campaign_id,
            adgroup_id: asset.adgroup_id,
            keyword_id: asset.keyword_id,
            word: asset.word,
            pw_asset_type: asset.pw_asset_type,
            score: asset.score,
            imp: asset.imp,
            asset_no: asset.asset_no,
          }
        }),
      }
    })
  }

  public async fetchDownloadAssetsChampionKW(data: {
    token: string,
    account: Account,
    campaignIds: string[],
    adgroupIds: string[],
  }): Promise<DownloadAssetsWithHeaders> {
    const endpoint = `/analytics/download/assets/champion_kw`
    return DataService.post<DownloadAssetResponses>({
      path: endpoint,
      param: {
        ss_id: data.account.ss_id,
        campaign_ids: data.campaignIds,
        adgroup_ids: data.adgroupIds,
      },
      token: data.token,
    }).then((responses) => {
      return {
        headers: responses.headers,
        assets: responses.results.map((asset) => {
          return {
            row_num: 0,
            ss_id: asset.ss_id,
            media_id: asset.media_id,
            account_id: asset.account_id,
            campaign_id: asset.campaign_id,
            adgroup_id: asset.adgroup_id,
            keyword_id: asset.keyword_id,
            word: asset.word,
            pw_asset_type: asset.asset_type,
            asset_no: asset.asset_no,
          }
        }),
      }
    })
  }

  public async fetchDownloadAssetsHigherKW(data: {
    token: string,
    account: Account,
    campaignIds: string[],
    adgroupIds: string[],
  }): Promise<DownloadAssetsWithHeaders> {
    const endpoint = `/analytics/download/assets/higher_kw`
    return DataService.post<DownloadAssetResponses>({
      path: endpoint,
      param: {
        ss_id: data.account.ss_id,
        campaign_ids: data.campaignIds,
        adgroup_ids: data.adgroupIds,
      },
      token: data.token,
    }).then((responses) => {
      return {
        headers: responses.headers,
        assets: responses.results.map((asset) => {
          return {
            row_num: 0,
            ss_id: asset.ss_id,
            media_id: asset.media_id,
            account_id: asset.account_id,
            campaign_id: asset.campaign_id,
            adgroup_id: asset.adgroup_id,
            keyword_id: asset.keyword_id,
            word: asset.word,
            pw_asset_type: asset.asset_type,
            asset_no: asset.asset_no,
          }
        }),
      }
    })
  }
}

export const analyticsUploadAssetRepository = new AnalyticsUploadAssetRepository()
