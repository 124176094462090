<template src="./Analytics.html"></template>
<style lang="scss" src="./sass/import.scss"></style>
<script lang="ts">
import {analyticsState} from '@/stores/state/analytics/analytics'
import HeaderComponent from '@/components/pages/Analytics/HeaderComponent/Header.vue'
import UploadAssetComponent from '@/components/pages/Analytics/UploadAssetComponent/UploadAsset.vue'
import UploadTdComponent from '@/components/pages/Analytics/UploadTdComponent/UploadTd.vue'
import {defineComponent, ref, computed} from 'vue'

const enum ANALYTICS_PAGE_TYPE {
  UPLOAD_ASSET = 'UploadAsset',
  UPLOAD_TD = 'UploadTd',
}

const AnalyticsComponent = defineComponent({
  components: {
    HeaderComponent,
    UploadAssetComponent,
    UploadTdComponent,
  },
  setup() {
    const pageType = ref<ANALYTICS_PAGE_TYPE>(ANALYTICS_PAGE_TYPE.UPLOAD_ASSET)
    const isLoading = computed(() => {
      return analyticsState.loading.main
    })
    const changePageTypeUploadAsset = () => {
      pageType.value = ANALYTICS_PAGE_TYPE.UPLOAD_ASSET
    }
    const changePageTypeUploadTd = () => {
      pageType.value = ANALYTICS_PAGE_TYPE.UPLOAD_TD
    }

    return {
      isLoading,
      pageType,
      changePageTypeUploadAsset,
      changePageTypeUploadTd,
    }
  },
})

export default AnalyticsComponent
</script>
