import {
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  CandidateAsset,
  ChampionRSAAd,
  MATCH_TYPE,
  PRE_COMBINED_RSA_FILTER_LOGIC,
  PRE_COMBINED_RSA_GENERATION_STRATEGY,
  PreCombinedAds,
  RSAAssetImp,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

export interface PreCombinedAdResponses extends DataServiceResponse {
  champion_ad: ChampionAdResponse | null
  candidate_ads: CandidateAdResponse[]
}

export interface CandidateAdAssetResponse extends DataServiceResponse {
  pre_combined_rsa_id: string
  pre_combined_rsa_asset_id: string
  generator_asset_id: number | null
  asset_type: ASSET_TYPE
  asset_text: string
  corrected_asset_text: string
  champion_ad_asset_imp: number | null
  display_order: number
  pinned_field: ASSET_PINNED_FILED | null
  keyword_id: string | null
  keyword: string | null
  match_type: MATCH_TYPE | null,
  generated_date: number | null
}

export interface CandidateAdResponse extends DataServiceResponse {
  ss_id: number
  media_id: number
  campaign_id: string
  adgroup_id: string
  selection_priority: number
  pre_combined_rsa_id: string
  ad_candidate_generation_strategy: PRE_COMBINED_RSA_GENERATION_STRATEGY
  filter_logic: PRE_COMBINED_RSA_FILTER_LOGIC
  assets: CandidateAdAssetResponse[]
}

export interface ChampionAdResponse extends DataServiceResponse {
  ss_id: number
  media_id: number
  account_id: string
  campaign_id: string
  adgroup_id: string
  ad_id: string
  ad_type: string
  asset_id: string | null
  final_url: string | null
  path_1: string | null
  path_2: string | null
  assets: RSAAssetImp[]
}

class PreCombinedAdRepository {
  public async fetchAssets(data: {
        token: string,
        ss_id: number,
        media_id: number,
        campaign_id: string,
        adgroup_id: string,
        selection_count: number,
      }): Promise<PreCombinedAds> {
    const endpoint = `/pre_combined_ad/assets`
    return DataService.get<PreCombinedAdResponses>({
      path: endpoint,
      param: {
        ss_id: data.ss_id,
        media_id: data.media_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        selection_count: data.selection_count,
      },
      token: data.token,
    }).then((pcas) => {
      if (!pcas.champion_ad) {
        return {
          champion_ad: null,
          candidate_ads: [],
        }
      }

      const rsaAssets: RSAAssetImp[] = pcas.champion_ad.assets.map((asset) => {
        return {
          word: asset.word,
          asset_type: asset.asset_type,
          pinned_field: asset.pinned_field,
          imp: asset.imp,
          generator_asset_id: null,
          keyword_id: null,
          keyword: null,
          asset_text_ng_tagged: asset.word,
          ng_check_types: new Set(),
        }
      })

      const champion_ad: ChampionRSAAd = {
        ss_id: pcas.champion_ad.ss_id,
        media_id: pcas.champion_ad.media_id,
        account_id: pcas.champion_ad.account_id,
        campaign_id: pcas.champion_ad.campaign_id,
        adgroup_id: pcas.champion_ad.adgroup_id,
        ad_id: pcas.champion_ad.ad_id,
        ad_type: pcas.champion_ad.ad_type,
        asset_id: pcas.champion_ad.asset_id,
        final_url: pcas.champion_ad.final_url,
        path_1: pcas.champion_ad.path_1,
        path_2: pcas.champion_ad.path_2,
        title_assets: rsaAssets.filter((asset) => asset.asset_type == ASSET_TYPE.TITLE),
        description_assets: rsaAssets.filter((asset) => asset.asset_type == ASSET_TYPE.DESCRIPTION),
      }
      const candidate_ads = pcas.candidate_ads.map((pcar) => {
        return {
          ss_id: pcar.ss_id,
          media_id: pcar.media_id,
          campaign_id: pcar.campaign_id,
          adgroup_id: pcar.adgroup_id,
          selection_priority: pcar.selection_priority,
          pre_combined_rsa_id: pcar.pre_combined_rsa_id,
          ad_candidate_generation_strategy: pcar.ad_candidate_generation_strategy,
          filter_logic: pcar.filter_logic,
          assets: pcar.assets.map((a) => {
            return {
              generator_asset_id: a.generator_asset_id,
              ss_id: pcar.ss_id,
              media_id: pcar.media_id,
              campaign_id: pcar.campaign_id,
              adgroup_id: pcar.adgroup_id,
              keyword_id: a.keyword_id,
              keyword: a.keyword,
              match_type: a.match_type,
              asset_type: a.asset_type,
              asset_text: a.corrected_asset_text,
              generated_date: a.generated_date,
              asset_text_default: a.corrected_asset_text,
              asset_text_ng_tagged: a.corrected_asset_text,
              is_editable: !!a.generator_asset_id,
              pinned_field: a.pinned_field,
              ng_check_types: new Set(),
            } as CandidateAsset
          }),
        }
      })
      return {
        champion_ad,
        candidate_ads,
      }
    })
  }
}

export const preCombinedAdRepository = new PreCombinedAdRepository()
