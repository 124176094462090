<div class="upload_asset">
    <h2>Asset Upload</h2>
    <div class="file_upload">
        <input class="upload_file" ref="inputFile" type="file" @change="setFile($event)">
        <div class="upload_area"
             @click="clickDragdropArea"
             v-bind:class="{'drag_over': isFileValid, 'drag_error': isFileError}"
             @dragover.prevent
             @dragleave.prevent
             @drop.prevent="setFile($event)">ドラック＆ドロップまたはクリック</div>
        <div v-show="isFileValid">
            <p>{{ fileName }}</p>
            <p>マッチする行数は{{ uploadCsvAssets.length }}件です</p>
        </div>
        <p class="error" v-show="isFileError" v-html="fileUploadErrorMessage"></p>
        <div class="btn upload" @click="downloadFormat">Format download</div>
        <div class="modal_action">
            <button class="btn run file_upload" v-bind:disabled="!isFileValid" @click="uploadDownloadAssets">Upload & Download</button>
        </div>
        <p>FormatDownloadから、utf-16/タブ区切りのファイルをご利用ください</p>
    </div>

    <div>
        <div v-show="!!selectedClient">
            <div class="">
                <Multiselect
                        class=""
                        placeholder="アカウントを検索"
                        v-model="value"
                        value-prop="value"
                        :options="selectOptions"
                        label="label"
                        track-by="track_by"
                        :searchable="true"
                        :option-height="20"
                        :limit="1000"
                        @select="onSelectAccount">
                </Multiselect>

                <div class="ssid" v-if="!!selectedAccount">SSID:{{ selectedAccount.ss_id }}</div>
            </div>
            <div class="id_select">
                <input type="radio" name="analytics_id_select" id="analytics_id_select_campaign_id" v-model="inputAnalyticsId" value="CampaignId" checked="checked">
                <label for="analytics_id_select_campaign_id" class=""><i class="fas fa-circle"></i>キャンペーンID</label>
                <input type="radio" name="analytics_id_select" id="analytics_id_select_adgroup_id" v-model="inputAnalyticsId" value="AdGroupId">
                <label for="analytics_id_select_adgroup_id" class=""><i class="fas fa-circle"></i>アドグループID</label>
            </div>
            <textarea v-model="inputCampaignAdgroupIds" placeholder="idを入力"></textarea>
            <div class="modal_action">
                <button class="btn run" v-bind:disabled="!canDownloadAssets" @click="downloadAssetsChampionKW">ダウンロード アセット</button>
                <button class="btn run" v-bind:disabled="!canDownloadAssets" @click="downloadAssetsHigherKW">ダウンロード キーワード x アセット</button>
            </div>
            <p class="error" v-show="!!downloadAssetsErrorMessage" v-html="downloadAssetsErrorMessage"></p>
        </div>
    </div>
</div>
