<template src='./Header.html'></template>

<script lang='ts'>
import { powerwordState } from '@/stores/state/powerword/powerword'
import { clientRepository } from '@/stores/repository/client'
import { accountRepository } from '@/stores/repository/account'
import { auth } from '@/stores/authorization-state'
import Multiselect from '@vueform/multiselect'
import { Client } from '@/stores/model/domain'
import {defineComponent, computed, ref} from 'vue'

const HeaderComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup() {
    const fetchSetClients = () => {
      powerwordState.setIsLoadingClients(true)
      return clientRepository.fetchClients({ token: auth.token })
        .then((v: Client[]) => {
          powerwordState.setIsLoadingClients(false)
          powerwordState.setClients(v)
      })
    }
    // initializer
    fetchSetClients()
    const clients = computed(() => {
      return powerwordState.clients
    })
    const isLoadingClients = computed(() => {
      return powerwordState.loading.clients
    })
    const selectedClient = computed(() => {
      return powerwordState.selectedClient
    })

    // Multiselect用
    const selectOptions = computed(() => {
      return powerwordState.clientOptions
    })
    const selectedClientValue = ref<Client | null>(null)

    const onSelectClient = (client: Client) => {
      powerwordState.setSelectedClient(client)
      powerwordState.setLoadingOn()
      // Account
      accountRepository.fetchAccounts({
        token: auth.token,
        clientId: client.client_id,
      }).then((as) => {
        powerwordState.setAccounts(as)
      }).then(() => {
        powerwordState.setLoadingOff()
      })
    }

    return {
      clients,
      isLoadingClients,
      selectedClient,
      onSelectClient,
      selectOptions,
      selectedClientValue,
    }
  },
})
export default HeaderComponent
</script>


